import React from 'react'

const Separator = () => {
  return (
    <div className="Separator">
      
    </div>
  )
}

export default Separator; 
