import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './components/App';
import ScrollToTop from './components/ScrollToTop'; 
import { BrowserRouter } from 'react-router-dom'; 
import { Provider } from 'react-redux'; 
import { createStore, applyMiddleware, compose } from 'redux'; 
import thunk from 'redux-thunk';
import reducers from './reducers'; 


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers, 
  composeEnhancers(applyMiddleware(thunk))
); 

ReactDOM.render(
<Provider store={store}>
  <BrowserRouter>
    <ScrollToTop/>
    <App /> 
  </BrowserRouter>
</Provider>, 
document.getElementById('root'));
