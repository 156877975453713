export const CHANGE_AUTH = "CHANGE_AUTH"; 
export const OPEN_CART = "OPEN_CART"; 
export const CLOSE_CART = "CLOSE_CART"; 
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const OPEN_SIDENAV = "OPEN_SIDENAV"; 
export const CLOSE_SIDENAV = "CLOSE_SIDENAV"; 
export const OPEN_SORT = "OPEN_SORT"; 
export const CLOSE_SORT = "CLOSE_SORT"; 
export const SORT = "SORT"; 
export const GET_SORT_DISTANCE = "GET_SORT_DISTANCE"; 
export const SET_NAV = "SET_NAV"; 
export const CHECKING_OUT = "CHECKING_OUT"; 
export const UPDATE_SUBTOTAL = "UPDATE_SUBTOTAL"; 
export const ADD_SHIPPING = "ADD_SHIPPING"; 
export const CLEAR_CART = "CLEAR_CART"; 
export const CLEAR_TOTAL = "CLEAR_TOTAL"; 
export const CLEAR_SORT = "CLEAR_SORT";
export const GET_PRODUCTS = "GET_PRODUCTS";